import React, { Component } from 'react';
import { UncontrolledCollapse, Button, ButtonGroup } from 'reactstrap';
import Route from './Route';
import { BusReservationList } from './BusReservationList';

export class Bus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            routes: [],
            loading: true,
            rSelected: 1,
        };

        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    }

    componentDidMount() {
        this.populateBusData();
    }

    childToParent = () => {
        window.location.reload(false);
    }

    onRadioBtnClick(rSelecteda) {
        this.setState({ rSelected: rSelecteda });
    }

    renderRoutes(routes) {
        var rsvdate = new Date();
        if (this.state.rSelected === 2) {
            rsvdate.setDate(rsvdate.getDate() + 1);
        }

        return (
            <div>
                <BusReservationList />
                <h4>予約日</h4>
                <ButtonGroup>
                    <Button color={this.state.rSelected === 1 ? "primary" : "secondary"} onClick={() => this.onRadioBtnClick(1)} active={this.state.rSelected === 1}>本日</Button>
                    <Button color={this.state.rSelected === 2 ? "primary" : "secondary"} onClick={() => this.onRadioBtnClick(2)} active={this.state.rSelected === 2}>明日</Button>
                </ButtonGroup>
                <p />
                <h4>バスルート情報</h4>
                <div className="d-flex flex-column">
                    {routes.map((item, index) => this.renderRoute(item, index, rsvdate))}
                </div>
            </div>
        );
    }

    renderRoute(route, index, rsvdate) {
        const togglerId = `toggler-${index}`;
        return (
            <div key={index}>
                <Button color="info" id={togglerId} className="mb-4">
                    {route.routeName}
                </Button>
                <UncontrolledCollapse toggler={`#${togglerId}`}>
                    <Route routeId={route.routeId} rsvdate={rsvdate} childToParent={this.childToParent} />
                </UncontrolledCollapse>
            </div>
        );
    }

    render() {
        const { loading, routes } = this.state;
        const contents = loading
            ? <p><em>読み込み中...</em></p>
            : this.renderRoutes(routes);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateBusData() {
        try {
            const response = await fetch('busreservation/getmasterroutes');
            const data = await response.json();
            this.setState({ routes: data, loading: false });
        } catch (error) {
            console.error('Failed to fetch bus data', error);
            this.setState({ loading: false });
        }
    }
}
