import React, { Component } from 'react';
import User from './User'
import { Table, Button } from 'reactstrap';

export class BusReservationList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reservations: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.populateBusReservations();
    }

    renderBusReservations(reservations) {
        return (
            <div>
                <h4>バス予約情報</h4>
                <Table striped>
                    <thead>
                        <tr>
                            <th>日付</th>
                            <th>路線</th>
                            <th>停留所</th>
                            <th>便</th>
                            <th>時間</th>
                            <th>予約メモ</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reservations.map((item, index) => this.renderReservation(item, index))}
                    </tbody>
                </Table>
            </div>
        );
    }

    renderReservation(reservation, index) {
        var departureDate = new Date(reservation.departureDate).toLocaleDateString();
        return (
            <tr key={index}>
                <td>{departureDate}</td>
                <td>{reservation.routeName}</td>
                <td>{reservation.stopName}</td>
                <td>{reservation.sequence}</td>
                <td>{reservation.departureTime}</td>
                <td>{reservation.memo}</td>
                <td>
                    <Button color="danger" onClick={() => this.handleCancel(reservation.stopId, reservation.departureDate, reservation.sequence)}>
                        キャンセル
                    </Button>
                </td>
            </tr>
        );
    }

    render() {
        const { loading, reservations } = this.state;
        const contents = loading
            ? <p><em>読み込み中...</em></p>
            : this.renderBusReservations(reservations);

        return (
            <div>
                {contents}
            </div>
        );
    }

    async populateBusReservations() {
        try {
            var userId = User.getNumber();
            const response = await fetch('busreservation/getbusreservations?userId=' + userId);
            const data = await response.json();
            this.setState({ reservations: data, loading: false });
        } catch (error) {
            console.error('Failed to fetch bus reservation data', error);
            this.setState({ loading: false });
        }
    }

    async handleCancel(stopId, rsvdate, sequence) {
        const confirmCancel = window.confirm('この予約をキャンセルしてもよろしいですか？'); // Confirmation prompt
        if (!confirmCancel) return;

        try {
            var userNumber = User.getNumber();
            let formData = new FormData();
            formData.append("personNum", userNumber);
            formData.append("stopId", stopId);
            formData.append("rsvdate", rsvdate);
            formData.append("sequence", sequence);

            await fetch("busreservation/cancelbusride", {
                method: "POST",
                body: formData
            }).then(async response => await response.json())
                .then(data => {
                    console.log(data);
                    this.populateBusReservations();
                });

        } catch (error) {
            console.error('Error occurred during cancellation', error);
        }
    }
}
